@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab&display=swap');

 .homePage {
    background-size: cover;
    display: flex;
    align-items: center;
    background-attachment: fixed;
    height: 100vh;
    border-radius: 0 12px 0 0 ;
 }
 .mainPageSkillBtn{
     background-color: rgb(0, 90, 207);
     font-weight: bold;
     padding: 10px 20px;
     border-radius: 7px;
     color: #fff;
     cursor: pointer;
     border-color: #004acf;
     font-size: 0.8vw;
 }
 .mainPageSkillBtn:hover {
    background-color: #004acf;
 }
 .mainPageAboutWrapper {
    background: #07335673;
    margin-bottom: 20%;
    margin: 0 50% 0 5%;
    color: #fff;
    text-align: left;
    padding: 2% 5%;
    border-radius: 7px;
}

.aboutText {
    color: #c6eeff;
    // font-style: italic;
    font-weight: 400;
    letter-spacing: 1px;
    font-family: 'Roboto Slab', serif;
    font-size: 0.8vw;
}

