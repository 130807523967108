.workExperience {
  font-size: 1vw;
}

.workRowHeader {
  cursor: pointer;
  font-size: 1.1vw;
  margin: 10px 0;
}

.workPageHeader {
  display: flex;
  align-items: center;
  justify-content: center;
 }


// COMMON

.linkStyle {
  text-decoration: none;
  color: #269bfd;
}

.linkStyle:hover {
  color: #2084d6;
}



.invisibleWork, .invisibleEducation {
    opacity: 0 ;
}

.visible{
  opacity: 1;
  transition: opacity 1s ease-in-out;
}

.hideWork 
{
  display: none;
}

.carretDownWork 
{
  margin: 0 5px;
  font-size: 1.3vw;
  
}