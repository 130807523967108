 
@import url('https://fonts.googleapis.com/css2?family=Modak&display=swap');

.App {
  display: flex;
  flex-direction: row;
}

.newLineArticle {
  margin-block-start: 0.3em;
    margin-block-end: 0.4em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

.sectionHeaders
{
  letter-spacing: 1px;
  font-family: "Modak", system-ui;
  font-weight: 200;
  font-style: normal;
  margin-left: 5px;
  font-size: 1.3vw;
  font-style: italic;
  text-shadow: 2px 2px 2px #a9cdff;
}

.mainPageHeader
{
  text-shadow: 1px 1px 1px #000000;
  font-style: italic;
  font-size: 1.3vw;
}


body{
  background-color: #dbf8ff;
  margin: 10px 1.5%;
}

*, ::after, ::before {
  box-sizing: border-box;
}

@media only screen and (max-width: 1366px) 
{
  .progress-value {
    font-size: 1.5vw !important;
  }

 
}
/* tablet */
@media only screen and (max-width: 991px) {


 
  .imgUrl {
    width: 30px !important;
    height: 30px !important;
    /* margin: 0 !important; */
    margin-right: 5px !important;
  }


  *{
    font-size: 1.6vw !important;
  }

  .yearSection{
    font-size:  3vw !important;
  }

  .popUpImages {
    flex: 0 0 47% !important;
  }
  .popUpWrapper {
    flex-direction: column;
  }
  .popUpImagesSectionWrapper {
    order: 5;
  }
  .mainPageAboutWrapper{
    margin-right: 20% !important;
  }
  .portfolioCell{
    flex: 0 0 48% !important;
  }

  .skillsSectionWrapper {
    flex-direction: column !important;
    align-items: normal !important
  }

  .skillHeaderWrapper {
    display: flex;
    align-items: center;
    margin: 0 auto;
    /* margin: 0 20px !important; */
  }

  .skillsSectionContent {
    border-left: none !important;
  }
  .contactInfoCellWrapper {
    display: block !important;
  }
}

/* Mobile */
@media only screen and (max-width: 768px) {

  .progress
  {
    margin:   10px!important;
  }
  .displayNone {
    display: block !important;
  }
  * {
    font-size: 2.8vw !important;
  }
  .App {
    flex-direction: column;
  }

  .navBar {
    position: static  !important;
    padding: 20px 50px 20px 88px !important;;
    display: none;
  }
  .flexJustifyCenter {
    display: block !important;
  }

  .portfolioCell{
    flex: 0 0 100% !important;
  }

  
  .homePage, .leftSideBar {
    height: auto  !important;
    min-height: 30vh  !important;
    border-radius: 0 !important;
  }

  .mainPageAboutWrapper {
    margin: 0 auto !important;
  }
 
.popUpContents{
  padding: 10px !important;
  border: none !important;
}
  
  .overlay-content{
    padding: 5% !important; 
    margin:  5% !important;
  }
  
 
  .flexJustifyCenter{
    display: flex !important;
    flex-wrap: wrap;
  }
  .progress {
    width: 40px !important;
    height: 40px !important;
    line-height: 40px !important;
  }
 
  .imgUrl {
    width: 20px !important;
    height: 20px !important;
    /* margin: 0 !important; */
    margin-right: 5px !important;
  }



 .portfolioWrapper {
   display: block !important;
 }

 .portfolioCellWrapper {
   padding: 10px !important;
   border-top: 1px solid #0326416e;
   border-left: none !important;
 }

 
  .mainPageSkillBtn {
    display: none;
  }

  .mainPageHeader {
    text-align: center;
  }

  .navBarToggleBtn {
    display: block;
    text-align: center;
  }
 

  .closebtn {
    z-index: 99 !important;;
    color: #000 !important;;
  }
  .timeline::after,
  .container::before,
  .container::after  { 
    background-color: transparent !important;
    border-color: transparent !important;
  }

  .container  {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .timeline {
    min-height: auto !important;
  }
  iframe {
    height: auto;
    padding-top: 20px;
  }

  .popUpHeader {
    margin-left: 0 !important;
  }

  .overlay-content {
    padding: 5px !important;
    margin: 5px !important;
  }

  .chevron {
    margin: 5px !important;
  }

  .sliderWrapper {
    width: 95% !important;
  }

  .progress:after,
  .progress, .progress-bar {
 
    border-width: 5px !important ;
  }
  .leftSideBar,
  .homePage {
    min-height: 0 !important ;
    margin-top: -1px !important ;
  }
  .leftSideBar {
    padding-bottom: 15px;
  }
  .navBarToggleBtn {
    display: block !important;
  }

  .aboutText {
    font-size: 2.5vw !important; 
  }
  .progress-value {
    font-size: 2.2vw !important; 
  }
  .portfolioCell{
    flex: 0 0 100% !important;
  }
}


/* TIMELINE */

/* The actual timeline (the vertical ruler) */
.timeline::after {
  content: '';
  position: absolute;
  width: 6px;
  background-color: #256acc;
  top: 0;
  bottom: 0;
  left: 10%;
  margin-left: -3px;
}

/* Container around content */
.container {
  text-align: left;
  padding: 12px 30px;
  position: relative;
  background-color: inherit;
  width: 80%;
  left: 10%;
}

/* The circles on the timeline */
.container::after {
  content: '';
  position: absolute;
  width: 25px;
  height: 25px;
  right: -17px;
  background-color: white;
  border: 4px solid #256acc;
  top: 15px;
  border-radius: 50%;
  z-index: 1;
}


/* Place the container to the right */
.right {
  left: 10;
}



/* Add arrows to the right container (pointing left) */
.right::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 22px;
  width: 0;
  z-index: 1;
  left: 20px;
  border: medium solid white;
  border-width: 10px 10px 10px 0;
  border-color: transparent #d2e5ff transparent transparent;
}

/* Fix the circle for containers on the right side */
.right::after {
  left: -16px;
}

/* The actual content */
.content {
  padding: 15px 30px;
  background-color: #d2e5ff;
  position: relative;
  border-radius: 6px;
}

/* Media queries - Responsive timeline on screens less than 600px wide */
@media screen and (max-width: 600px) {
  /* Place the timelime to the left */
  .timeline::after {
  left: 31px;
  }
  
  /* Full-width containers */
  .container {
  width: 100%;
  padding-left: 70px;
  padding-right: 25px;
  }
  
  /* Make sure that all arrows are pointing leftwards */
  .container::before {
  left: 60px;
  border: medium solid white;
  border-width: 10px 10px 10px 0;
  border-color: transparent white transparent transparent;
  }

  /* Make sure all circles are at the same spot */
  .left::after, .right::after {
  left: 15px;
  }
  
  /* Make all right containers behave like the left ones */
  .right {
  left: 0%;
  }
}
/* The actual timeline (the vertical ruler) */
.timeline {
  position: relative;
  padding: 30px 0;
  min-height: 70vh;
}

.extraMargin {
  margin-bottom: 20px; /* Adjust as needed */
}