.educationWrapper 
{
 border-radius: 5%;
  background-color: #f5f9ff;
  // box-shadow:1px 1px 1px #bad5ff;
}
.educationPageHeader {
display: flex;
align-items: center;
justify-content: center;
}

// COMMON
.linkStyle {
    text-decoration: none;
    color: #269bfd;
  }
  
  .linkStyle:hover {
    color: #2084d6;
  }
  
  .workAnimation {
    opacity: 0 !important;
    visibility: hidden !important;
    position: relative;
    background-color: rgb(255, 255, 255) !important;
    word-break: break-all;
    overflow: hidden;
    transition: transform 0s linear !important;
  }
  
  .test {
    max-height: auto;
    transition: 1.2s;
    opacity: 1;
    transition-delay: 100ms;
  }

.hideWork 
{
  display: none;
}

.educationListHeader
{
  margin: 10px 0;
  cursor: pointer;
  font-size: 1.1vw;
}

.caretIcon 
{
  margin: 0 5px;
  font-size: 1.3vw;
  
}