@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab&display=swap');

.square-animation {
  transform: rotate(0deg) !important;
  transition: transform 0s linear !important;
}

.flexJustifyCenter {
  display: block;
  align-items: center;
  text-align: left;
}

.skillsSectionWrapper {
  display: flex;
  flex-direction: row;
  padding: 20px 10px;
  font-style: italic;
  font-family: 'Roboto Slab', serif;
}

.skillHeaderWrapper {
  flex: 0 0 15%; 
  text-align: center;
}

.skillHeader {
  margin: 0;
}

.skillsSectionContent {
  border-left: 1px solid #0326416e;
  display: flex;
  flex-wrap: wrap;
  flex: 0 0 85%;
}

.imgUrl {
  background-position: center;
  margin: 0 auto;
  width: 50px;
  height: 50px;
  background-size: contain;
  background-repeat: no-repeat;
}

.skillPageHeader {
  display: flex;
  align-items: center;
  justify-content: center;
}
