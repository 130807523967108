@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab&display=swap');
.overlay {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 999999;
    top: 0;
    left: 0;
    background-color: rgba(45, 61, 84, 0.9);
    overflow-x: hidden;
    transition: 0.3s;
    // font-family: 'Roboto Slab', serif;
  }
  .popUpHeader {
    font-family: 'Roboto Slab', serif;
    margin-bottom: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 35%;
  }
 
  
  .overlay-content {
    background-color: #fff;
    color: rgb(12, 0, 56);
    position: relative;
    top: 0;
    bottom: 0;
    margin: 0 10%;
    text-align: center;
    margin-top: 55px;
    min-height: 90%;
    border-radius: 10px;
    padding: 4% 4% 2% 6%;
  }
  
  .overlay a {
    padding: 8px;
    text-decoration: none;
    color: #818181;
    display: block;
    transition: 0.3s;
  }
  
  .overlay a:hover, .overlay a:focus {
    color: #f1f1f1;
  }
  
  .overlay .closebtn {
    color: #fff;
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 45px;
    font-size: 30px;
  }
  
  @media screen and (max-height: 450px) {
    .overlay a {font-size: 20px}
    .overlay .closebtn {
    font-size: 40px;
    top: 15px;
    right: 35px;
    }
  }

  .chevron {
    background-size: cover;

    cursor: pointer;
    margin: 20px;
  }

  .sliderChevrons  {
    color: #0a3d44;
  }

  .sliderChevrons:hover  {
     color:  #0f5962
  }

  .lineOfText {
    text-align: left;
    //flex: 0 0 25%;
    margin: 0 10px;
    font-size: 0.7vw;
    padding: 5px 0;
  }

 
  .photoSliderHeader {
    font-family: 'Roboto Slab', serif;
    color: #aeeeff
  }

   
  .loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    color: #555;
    font-size: 14px;
  }
  
  .popUpimage.hidden {
    display: none;
  }
  

.popUpcellWrapper {
  display: flex;
  text-align: left;
  margin: 10px 0;
}

.popUpCellHeader {
  font-weight: bold;
  flex: 0 0 20%;
}

.popUpCellBody {
  word-break: break-word;
}

.popUpWrapper {
  display: flex;
  word-break: break-all;
  font-size: 1vw;
}

.popUpImagesSectionWrapper {
  flex:  0 0 54%;
  padding: 10px;
}

.popUpImagesSectionContent {
  display: flex;
  flex-wrap: wrap;
}

.popUpImages {
  border-radius: 5px;
  height: auto;
  flex: 1 1 30%;
  border: 1px solid #9bb8e3;
  box-shadow:  0px 0px 2px 2.5px rgba(0, 0, 0, 0.25);
  margin: 1.5% 1.5%;
  padding: 10px;
  cursor: zoom-in;
}

.popUpimage {
  width: 100%;
  height: 100%;
}

.popUpRightBarWrapper {
flex: 0 0 46%;
margin-left: 1%;
padding-left: 3%;
border-left: 1px solid #07007817;
}

.popUpRightBarContent {
display: flex;
text-align: left;
margin-left: 10px 0;
align-items: center;
}

.rightBarLinkHeader {
  flex: 0 0 20%;
  font-weight: bold;
}

.rightBarLink {
  font-size: 1vw;
  color: #759dd8;
  padding: 0
}

.tehnologiesWrapper {
  display: flex;
}

.tehnologiesHeader{
font-weight: bold;
text-align: left;
flex: 0 0 20%;
}

.tehnologiesCellWrapper {
  display: flex;
  flex-wrap: wrap;
}

.featuresHeader {
  font-weight: bold;
  text-align: left;
}

.featuresHeader {
  padding-left: 5%;
}

.popUpIcons,
.popUpHeaderIcon 
 {
  color: #bcd7ff;
  margin-right: 3px;
}

.featuresIcons {
  color: #759dd8;
}