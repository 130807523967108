
.portfolioBG 
{
  border-radius: 5%;
  background-color: #f5f9ff;
  // box-shadow:1px 1px 1px #bad5ff;
  
}

.yearSection {
    flex: 0 0 15%;
    text-align: center;
    font-size: 2vw;
    letter-spacing: 2px;
    font-family: "Modak", system-ui;
    font-weight: 400;
    font-style: normal;
    
    
  }
  
  .portfolioCellWrapper {
    padding-left: 20px;
    border-left: 1px solid #0326416e;
    flex: 0 0 85%;
    display: flex;
    flex-wrap: wrap;
  }

  .readMoreBtn {
    padding: 3px;
    color: #fff;
    border-radius: 5px;
    background-color: #4672b1;
    box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.25);
    width: 80%;
    margin: 0 auto;
    font-weight: bold;
    cursor: pointer;
  }

  
.portfolioCell {
    min-height: 180px;
    background-color: #afd0ff;
    flex: 0 0 23%;
    display: flex;
    flex-direction: column;
    word-break: break-all;
    margin: 1%;
    text-align: center;
    padding: 10px;
    border-radius: 7px;
    box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.25);
    color: #00208F;
  }
  
  .portfolioWrapper {
    display: flex;
    align-items: center;
    padding: 10px 5px;
  }
  
  .portfolioLogo {
    max-height: 120px;
    margin: 0 auto;
    border-radius: 5px;
    max-height: 90px;
  }
  
  .ShortDescription, 
  .portfolioHeader {
    margin: 5px;
    font-size: 0.75vw;
  }

  .portfolioLogoWrapper {
      flex: 1;
      align-items: center;
      display: flex;
  }

.portfolioPageHeader {
    display: flex;
    align-items: center;
    justify-content: center;
}