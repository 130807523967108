.navBar {
    text-align: left;
    padding: 20px 0px 20px 60px;
    letter-spacing: 0.5px;
    position: -webkit-sticky;
    position: sticky;
    top: 20px;
    text-transform: uppercase;
}

.navBar > a {
    display: block;
    text-decoration: none;
    color: #d2e3ff;
    margin: 35px 0;
    cursor: pointer;
}

.selected,
.active svg {
    color: aqua !important;
}

.linkText {
    margin-left: 5px;
    font-size: 0.9vw;
    text-shadow: 2px 2px 2px #000000;
}
.active .linkText {
    font-weight: bold;
    color: rgb(204, 252, 255);    
}

.navBarToggleBtn {
    text-align: right;
    margin-right: 20px;
    display: none;
    cursor: pointer;
}

.menuAwesomeIcons
{
    width: 20px !important;
}