 

.progress{
    width: 60px;
    height: 60px;
    line-height: 60px;
    background: none;
    margin: 20px ;
    margin-bottom: 0;
    box-shadow: none;
    position: relative;
}

.progress:after{
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 9px solid #f2f5f5;
    position: absolute;
    top: 0;
    left: 0;
}
.progress > span{
    width: 50%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    z-index: 1;
}
.progress .progress-left{
    left: 0;
}
.progress .progress-bar{
    width: 100%;
    height: 100%;
    background: none;
    border-width: 9px;
    border-style: solid;
    position: absolute;
    top: 0;
}
.progress .progress-left .progress-bar{
    left: 100%;
    border-top-right-radius: 80px;
    border-bottom-right-radius: 80px;
    border-left: 0;
    -webkit-transform-origin: center left;
    transform-origin: center left;
    transition: transform 0.3s linear;
}
.progress .progress-right{
    right: 0;
}

 
 
//for left
.progress .progress-right .progress-bar{
    left: -100%;
    border-top-left-radius: 80px;
    border-bottom-left-radius: 80px;
    border-right: 0;
    -webkit-transform-origin: center right;
    transform-origin: center right;
    transition: transform 0.5s linear;
}

.progress .progress-value{
    width: 100%;
    height: 100%;
    font-size: 0.8vw;
    font-weight: 400;
    color: rgb(211, 16, 16);
    text-align: center;
    position: absolute;
}
.progress.blue .progress-bar{
    border-color: #26abfd;
}




@media only screen and (max-width: 990px){
    .progress{ margin-bottom: 20px; }
}

.progressbarSkillName {
    text-align: center;
    margin: 8px;
}