@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab&display=swap');
.overlay2 {
    background: radial-gradient(#7dd3eb, #005067);
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 999999;
    top: 0;
    left: 0;
    background-color: rgba(45, 61, 84, 0.9);
    overflow-x: hidden;
    transition: 0.3s;
    // font-family: 'Roboto Slab', serif;
  }
  
  .popUpHeader {
    font-family: 'Roboto Slab', serif;
    margin-bottom: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
 
  
  .overlay-content2 {
    background-color: transparent;
    display: flex;
    align-items: center;
    user-select: none;
    margin: 0 !important;
    flex-direction: column;
    color: rgb(12, 0, 56);
    position: relative;
    top: 0;
    bottom: 0;
    margin: 0 10% !important;
    text-align: center;
    margin-top: 55px;
    min-height: 90%;
    border-radius: 10px;
  }

  .overlay a {
    padding: 8px;
    text-decoration: none;
    color: #818181;
    display: block;
    transition: 0.3s;
  }
  
  .overlay a:hover, .overlay a:focus {
    color: #f1f1f1;
  }
  
  .overlay .closebtn {
    color: #fff;
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 45px;
    font-size: 30px;
  }
  
  @media screen and (max-height: 450px) {
    .overlay a {font-size: 20px}
    .overlay .closebtn {
    font-size: 40px;
    top: 15px;
    right: 35px;
    }
  }

  .chevron {
    background-size: cover;

    cursor: pointer;
    margin: 20px;
  }

  .sliderChevrons  {
    color: #0a3d44;
  }

  .sliderChevrons:hover  {
     color:  #0f5962
  }

  .lineOfText {
    text-align: left;
  }
 
  .photoSliderHeader2 {
    font-family: 'Roboto Slab', serif;
    color: #aeeeff;
    text-align: center;
    margin: 30px 0 20px 0;
  }

   
  

.popUpcellWrapper {
  display: flex;
  text-align: left;
  margin: 10px 0;
}

.popUpCellHeader {
  font-weight: bold;
  flex: 0 0 20%;
}

.popUpCellBody {
  word-break: break-word;
}

.imgToShow {
    height: auto;
    margin: 0 auto;
    width: 90%;
    border-radius: 9px;
    max-height: 600px;
}

