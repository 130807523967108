.logoImg {
    width: 170px;
    height: 160px;
    display: block;
    margin: 20px auto;
    border: 5px solid #0675b7;
    border-radius: 99%;
}

.LogoArea {
    padding: 10px;
    margin: 20px auto;
    text-align: center;
}

.logoImg {
    width: 180px;
    height: 170px;
    background-size: cover;
    border-radius: 99%;
    padding: 10px;
    border: 5px solid #0675b7;
    margin: 0 auto;
    background-position: center;
}

.personalName
{
    letter-spacing: 2px;
    text-shadow: 2px 2px 2px #000000;
    font-weight: 600;
 
    font-size: 1vw;
    font-style: italic;
}
 