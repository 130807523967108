.contactInfoCellWrapper {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.contactInfoContent {
  font-size: 0.7vw;
  display: flex;
  align-items: center;
  flex: 0 0 31%;
  height: 100%;
  margin: 1%;
}

.contactInfoCellImgWrapper {
  padding: 10px;
}

.contactInfoHeader {
 color: #4881b9;
 font-size: 0.8vw;
 margin:  0 0 5px 0;
}
.contactInfoCellImg{
  width: 45px;
  height: 45px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.contactHeader {
  display: flex;
  align-items: center;
  justify-content: center;
}

.contactCellContentWrapper {
  flex: 1;
  align-items: center;
}
.contactsWrapper{
  min-height: 85vh;
}
